<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img class="size-full wp-image-23470 aligncenter" src="@/assets/news/17/1.png" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>获批人数累计近三万人</p>
        <p>根据希腊移民局新发布的黄金签证数据：</p>
        <p>截止到2021年12月31日，2021年希腊累计向1035组投资人发放了黄金居留签证。累计获批主申请人9610人，中国籍6405人。</p>
        <p><img class="size-full wp-image-23471 aligncenter" src="@/assets/news/17/2.png" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>累计发放28767张黄金签证，其中中国投资者高达19412位，占总数67.5%。</p>
        <p><img class="size-full wp-image-23472 aligncenter" src="@/assets/news/17/3.png" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>据新数据显示，与2020年相比，希腊在2021年向非欧盟公民发放的“黄金签证”数量增长10.3%，新发放1035份五年期居留许可，高于前年的938份。也就是说希腊“黄金签证”发放量在2021年已经呈现复苏之势。</p>
        <p>总体而言，自项目2013年开启以来，接近3万人（主申请人及其家属）获得了希腊黄金签证，其中大部分是中国投资者，占比超过2/3。</p>
        <p><img class="size-full wp-image-23473 aligncenter" src="@/assets/news/17/4.png" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>希腊黄金签证获批申请人来源地</p>
        <p>但是，美国投资者在希腊黄金签证中的存在感越来越强。而结合前不久葡萄牙黄金签证的数据，可以看出，美国投资者对欧盟国家的身份越发看重。</p>
        <p>希腊为何被投资者青睐</p>
        <p>从希腊近期表现来看，无论是财政政策，还是经济数据，希腊都没有辜负投资者的青睐：希腊经济已经开始复苏，投资频现积极信号！</p>
        <p>在财政方面，希腊表现良好。参与欧洲央行的量化宽松等计划不仅降低了希腊利率，也有利地帮助了希腊经济增长。</p>
        <p>经济方面，希腊已经成功地从海外（微软、辉瑞、亚马逊）吸引到了重要投资，雅典埃利尼科旧机场的新开发项目也已开始。（参考阅读地铁新线开通，机场改建，获得300亿欧元资金注入，这个地方真心“赞”啊！）与此同时，希腊政府也表示未来还将需要更多、更广泛的新投资。</p>
        <p>欧洲当局在2021年末时预测，希腊经济今年增长7.1%，2022年将增长5.2%，这一前景比政府2021年初预估的6.9%更为乐观。根据政府的预测，到明年年底希腊GDP应该会恢复到疫情前的水平。</p>
        <p>日前，希腊欧元银行副总裁Vassiliou也表示，明年希腊旅游业将迎来“爆炸性的一年”，预计相关需求将是过去20年来尤为旺盛的一年。</p>
        <p>种种迹象都表明，希腊在经历住疫情考验的同时，还凭借优质的旅游资源和25万欧元起就可购房移民的政策，成为了全球投资者眼中的“宝地”。</p>
        <p>有远见的投资人已先人一步，抢滩希腊房市。如果您也有海外身份、资产配置、子女教育等需求，一定要提前行动起来！</p>

      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news17",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:17,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>